<template>
  <div class="create-post">
    <div class="blog-container" v-if="blog">
      <div class="blog-info">
        <input
          type="text"
          placeholder="Enter Blog Title"
          v-model="blog.title"
          style="flex-grow: 1"
        />
        <div class="upload-file">
          <input
            ref="blogPhoto"
            id="blog-photo"
            @change="fileChange"
            class="d-none"
            type="file"
            accept="image/*"
          />
        </div>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" @click="onUpdateCoverPhoto">
              <v-icon>mdi-image-edit</v-icon>
            </v-btn>
          </template>
          <span>Update cover photo</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
              class="mx-3"
              @click="onDeleteBlog"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
          <span>Delete blog</span>
        </v-tooltip>
      </div>
      <div>
        <v-img
          :src="selectedImage ? selectedImage : blog.cover"
          v-if="selectedImage || blog.cover"
        />
      </div>
      <div class="editor">
        <vue-editor
          :editorOptions="editorSettings"
          v-model="blog.html"
          useCustomImageHandler
          @image-added="imageHandler"
        />
      </div>
      <div class="blog-actions">
        <v-btn
          @click="uploadBlog"
          :loading="loading"
          :small="$vuetify.breakpoint.xs"
          rounded
          >Update Blog</v-btn
        >
        <v-btn @click="onBlogPreview" :small="$vuetify.breakpoint.xs" rounded
          >Post Preview</v-btn
        >
      </div>
      <confirm-delete-dialog
        :deleteDialog="deleteDialog"
        :loading="loading"
        :onClose="onCloseDialog"
        :onConfirm="onConfirmDelete"
      />
      <blog-preview
        :dialog="previewDialog"
        :onClose="onClosePreview"
        :blog="blog"
      />
      <v-snackbar v-model="error">
        {{ errorMsg }}
      </v-snackbar>
    </div>
  </div>
</template>
<script>
import Quill from "quill";
import { mapActions, mapState } from "vuex";
import ConfirmDeleteDialog from "../../components/common/ConfirmDeleteDialog.vue";
window.Quill = Quill;
const ImageResize = require("quill-image-resize-module").default;
Quill.register("modules/imageResize", ImageResize);
const VideoResize = require("quill-video-resize-module").default;
Quill.register("modules/VideoResize", VideoResize);
import BlogPreview from "./BlogPreview.vue";

export default {
  components: { ConfirmDeleteDialog, BlogPreview },
  name: "CreatePost",
  data() {
    return {
      file: null,
      error: null,
      errorMsg: null,
      loading: null,
      blog: null,
      editorSettings: {
        modules: {
          imageResize: {},
          VideoResize: {},
        },
      },
      selectedImage: null,
      deleteDialog: false,
      previewDialog: false,
    };
  },
  methods: {
    ...mapActions("blog", {
      uploadBlogImage: "uploadBlogImage",
      getBlogDetails: "getBlogDetails",
      updateBlog: "updateBlog",
      deleteBlog: "deleteBlog",
    }),
    fileChange() {
      if (this.$refs.blogPhoto.files.length != 0) {
        this.file = this.$refs.blogPhoto.files[0];
        this.selectedImage = URL.createObjectURL(this.file);
      }
    },
    openPreview() {
      this.$store.commit("openPhotoPreview");
    },
    imageHandler(file, Editor, cursorLocation, resetUploader) {
      var formData = new FormData();
      formData.append("file", file);
      this.uploadBlogImage(formData)
        .then((res) => {
          Editor.insertEmbed(cursorLocation, "image", res);
          resetUploader();
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data.message);
          } else {
            console.log(error);
          }
        });
    },
    uploadBlog() {
      if (this.blog.title.length !== 0 && this.blog.html.length !== 0) {
        var formData = new FormData();
        formData.append("_id", this.blog._id);
        formData.append("html", this.blog.html);
        formData.append("title", this.blog.title);
        if (this.file) {
          formData.append("cover", this.file);
        }
        this.loading = true;
        this.updateBlog(formData).then((res) => {
          console.log(res);
          this.loading = false;
          this.$router.push({
            name: "blog-details",
            query: { _id: res._id },
          });
        });
        return;
      }
      this.error = true;
      this.errorMsg = "Please ensure Blog Title & Blog Post has been filled!";
      setTimeout(() => {
        this.error = false;
      }, 5000);
      return;
    },
    onBlogPreview() {
      this.previewDialog = true;
    },
    onClosePreview() {
      this.previewDialog = false;
    },
    onUpdateCoverPhoto() {
      this.isSelectingPhoto = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelectingPhoto = false;
        },
        { once: true }
      );
      this.$refs.blogPhoto.click();
    },
    onDeleteBlog() {
      this.deleteDialog = true;
    },
    onCloseDialog() {
      this.deleteDialog = false;
    },
    onConfirmDelete() {
      this.loading = true;
      this.deleteBlog({ _id: this.blog._id })
        .then(() => {
          this.loading = false;
          this.$router.push({ name: "blog-feeds" });
        })
        .catch((error) => {
          this.loading = false;
          if (error.response) {
            this.snackbar = true;
            this.errorMessage = error.response.data.message;
          } else {
            this.snackbar = true;
            this.errorMessage = error;
          }
        });
    },
  },
  computed: {
    ...mapState("auth", {
      profile: "profile",
    }),
  },
  mounted() {
    this.getBlogDetails({ _id: this.$route.query._id })
      .then((res) => {
        this.blog = res;
        if (this.blog.poster != this.profile._id) {
          this.$router.push({ name: "404" });
        }
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  },
};
</script>

<style lang="scss">
.create-post {
  position: relative;
  height: 100%;
  button {
    margin-top: 0;
  }
  .router-button {
    text-decoration: none;
    color: #fff;
  }
  // label,
  // button,
  // .router-button {
  //   transition: 0.5s ease-in-out all;
  //   align-self: center;
  //   font-size: 14px;
  //   cursor: pointer;
  //   border-radius: 20px;
  //   padding: 12px 24px;
  //   color: #fff;
  //   background-color: #b5e539;
  //   text-decoration: none;
  //   &:hover {
  //     background-color: rgba(48, 48, 48, 0.7);
  //   }
  // }
  .blog-container {
    position: relative;
    height: 100%;
    padding: 10px 25px 60px;
  }
  // error styling
  .invisible {
    opacity: 0 !important;
  }
  .err-message {
    width: 100%;
    padding: 12px;
    border-radius: 8px;
    color: #fff;
    margin-bottom: 10px;
    background-color: #303030;
    opacity: 1;
    transition: 0.5s ease all;
    p {
      font-size: 14px;
    }
    span {
      font-weight: 600;
    }
  }
  .blog-info {
    display: flex;
    margin-bottom: 32px;
    input:nth-child(1) {
      @media (min-width: 480px) {
        min-width: 100px;
      }
    }
    input {
      transition: 0.5s ease-in-out all;
      padding: 10px 4px;
      border: none;
      border-bottom: 1px solid #303030;
      &:focus {
        outline: none;
        box-shadow: 0 1px 0 0 #303030;
      }
    }
    .upload-file {
      margin-left: 16px;
      position: relative;
      display: flex;
      input {
        display: none;
      }
      .preview {
        margin-left: 16px;
        text-transform: initial;
      }
      span {
        font-size: 12px;
        margin-left: 16px;
        align-self: center;
      }
    }
  }
  .editor {
    height: 60vh;
    display: flex;
    flex-direction: column;
    .quillWrapper {
      position: relative;
      display: flex;
      flex-direction: column;
      height: 100%;
    }
    .ql-container {
      display: flex;
      flex-direction: column;
      height: 100%;
      overflow: scroll;
    }
    .ql-editor {
      padding: 20px 16px 30px;
    }
    iframe {
      pointer-events: none;
    }
  }
  .blog-actions {
    margin-top: 32px;
    button {
      margin-right: 16px;
    }
  }
}
@media (min-width: 960px) {
  .create-post {
    width: calc(100% - 425px);
  }
}
@media (max-width: 961px) {
  .create-post {
    width: 100%;
  }
}
</style>
